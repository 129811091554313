.dlazdice-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
}

.dlazdice-card {
    flex: 1 1 calc(25% - 20px);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    transition: transform 0.3s ease;
}

.dlazdice-card:hover {
    transform: translateY(-5px);
}

.icon-wrapper {
    font-size: 48px; /* Velikost ikony */
    color: #007bff; /* Barva ikony */
    margin-bottom: 15px;
}

.dlazdice-card .card-title {
    font-weight: bold;
    margin-bottom: 15px;
}

.dlazdice-card .card-text {
    text-align: center;
    color: #6c757d;
}

/* Responzivní úpravy */
@media (max-width: 1199px) {
    .dlazdice-card {
        flex: 1 1 calc(33.333% - 20px); /* Tři dlaždice na řadě pro large zařízení */
    }
}

@media (max-width: 991px) {
    .dlazdice-card {
        flex: 1 1 calc(50% - 20px); /* Dvě dlaždice na řadě pro medium zařízení */
    }
}

@media (max-width: 767px) {
    .dlazdice-card {
        flex: 1 1 calc(100% - 20px); /* Jedna dlaždice na řadě pro small zařízení */
    }
}
