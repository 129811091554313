.timeline-item {
  transition: transform 0.2s ease-in-out;
}

.timeline-item:hover {
  transform: translateY(-10px);
}

.timeline-icon-container {
  transition: background-color 0.2s ease-in-out;
}

.timeline-icon {
  color: #007bff; /* Nastavte barvu ikon podle vašeho designu */
}

.timeline-card {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: box-shadow 0.2s ease-in-out;
}

.timeline-card:hover {
  box-shadow: 0 6px 12px rgba(0,0,0,0.2);
}

.timeline-year {
  font-size: 1rem;
}

.timeline-title {
  font-size: 1.5rem;
  margin-top: 0.5rem;
}

.timeline-text {
  font-size: 1rem;
  margin-top: 0.5rem;
}

@media (max-width: 767px) {
  .timeline-icon-container, .timeline-content {
    text-align: center;
  }
}
