.navbar {
    background-color: #1c212dfa !important;
    min-height: 10vh;
    /* margin-bottom: 10vh; */
}

.navbar-brand {
    color: #61DAFB !important;
    font-family: 'Kaushan Script', cursive;
}

.nav-link {
    color: #ffffff !important;
    transition: color 0.3s ease-in-out;
}

.nav-link:hover {
    color: #61DAFB !important;
}

.navbar-toggler {
    border-color: #61DAFB;
}

.navbar-toggler:hover {
    background-color: #61DAFB;
}
